<template>
    
    <div id="slippageDashboardChart" class="slippage" v-show="Object.keys(store.tradingSlippageChart).length && store.tradingSlippageChart.series && store.tradingSlippageChart.series.length && store.tradingSlippageChart.series[0].data.length"></div>
    <Nodata v-if="!store.singleLoading && Object.keys(store.tradingSlippageChart).length && store.tradingSlippageChart.series && store.tradingSlippageChart.series.length && store.tradingSlippageChart.series[0].data.length == 0"></Nodata>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {

        }
    },
    methods: {
        drawSlippageChart(){
            var root = am5.Root.new("slippageDashboardChart");
            // Set themes
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root),
            ]);
             var chart = root.container.children.push(am5xy.XYChart.new(root, {
                   panX: false,
                    panY: false,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    paddingLeft: 0,
                paddingRight: 0,
                }));
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 });
                xRenderer.labels.template.setAll({
                  rotation: -70,
                  centerY: am5.p50,
                  centerX: am5.p100,
                  paddingRight: 15
            });
            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
              maxDeviation: 0,
              categoryField: "x",
              renderer:  xRenderer,
              // tooltip: am5.Tooltip.new(root, {})
            }));
            var label2 = am5.Label.new(root, {
                text: "[#666] Broker [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );


            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            var label1 = am5.Label.new(root, {
                rotation: -90,
                y: am5.p50,
                text: "[#666] Pips[/]",
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );


             let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });

             var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            chart.get("colors").set("colors", [
                am5.color(0x4572a7),
            ]);
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });

            // Create series
            
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                legendLabelText: 'Pips/Broker',
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "y",
              sequencedInterpolation: true,
              categoryXField: "x",
              min : 0,
              tooltip: am5.Tooltip.new(root, {
                labelText:"{categoryX} : {valueY}"
              })
            }));

            series.columns.template.setAll({  width: am5.percent(50),});
            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });


            // Set data
            var data = this.store.tradingSlippageChart.series[0].data;
            xAxis.data.setAll(data);
            legend.data.push(series);
            series.data.setAll(data);
            // Make stuff animate on load
            series.appear(1000);
            chart.appear(1000, 100);
        },
        getTrendingSlippageChartData(){
            let formData  = {};
            this.store.getTrendingSlippageChartData(formData,true,this.store.userSelectedAccount.providerId).then( () =>{
                if(Object.keys(this.store.tradingSlippageChart).length && this.store.tradingSlippageChart.series && this.store.tradingSlippageChart.series.length > 0)
                    am5.ready(() => {
                    this.drawSlippageChart()
                });
            })
        }
    },
    mounted() {
        this.getTrendingSlippageChartData()
    },
}
</script>
<style>
    .slippage{
        height: 400px;
        width: 100%;
    }
</style>