<template>
    <div
        class="bg-white boxed radius20 div-height"
        v-if="Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length"
    >
        <div class="row mb-3 viewTabOne" v-if="$parent.Tab == 1">
            <div class="col-12">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <div class="content">
                        <div class="d-flex align-items-center flex-wrap stDes">
                            <!-- <v-lazy-image width="13" class="d-block" src="/assets/images/idea.svg" alt="STRATEGY" title="North East Way EA." /> -->
                            <h5 class="mb-md-0">{{$t('stats.statscontent1')}}</h5>
                            <div class="all-small-icon d-flex flex-wrap align-items-center mx-md-4">
                                <span class="me-2" v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.brokerId">
                                    <v-lazy-image
                                        class="d-block"
                                        :src="static_vars.imagesURL + 'webservices/Image.ashx?type=broker&size=S&id=' + store.traderDetail.trader.stats.profile.brokerId + '&flavor=ZuluTrade'"
                                        :alt="store.traderDetail.trader.stats.profile.brokerShortName"
                                        :title="store.traderDetail.trader.stats.profile.brokerShortName"
                                    />
                                </span>
                                <span class="otherBroker" v-else>{{$t('stats.statscontent2')}} </span>
                                <span class="mx-1" v-if="store.traderDetail.trader.stats.badges.hasLiveAccount">
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon1.png" :alt="$t('stats.statscontent3')" :title="$t('stats.statscontent4')" />
                                </span>
                                <span class="mx-1" v-if="store.traderDetail.trader.stats.badges.ea">
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon4.png" :alt="$t('stats.statscontent5')" :title="$t('stats.statscontent6')" />
                                </span>
                                <span
                                    class="mx-1 tooltipbutton"
                                    v-if="store.traderDetail.trader.stats.badges.phoneVerified && store.traderDetail.trader.stats.badges.addressVerified && store.traderDetail.trader.stats.badges.identificationVerified"
                                >
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent7')" />
                                    <span class="tooltiptext">
                                        <span class="d-block border-bottom pb-2">{{$t('stats.statscontent8')}} </span>
                                        <span class="d-flex mt-2">
                                            <v-lazy-image class="me-1" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent9')" :title="$t('stats.statscontent10')" />
                                            <span v-html="$t('stats.statscontent11')"> </span>
                                        </span>
                                        <span class="d-flex mt-2">
                                            <v-lazy-image class="me-1" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent12')" :title="$t('stats.statscontent13')" />
                                            <span v-html="$t('stats.statscontent14')"></span>
                                        </span>
                                        <span class="d-flex mt-2">
                                            <v-lazy-image class="me-1" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent15')" :title="$t('stats.statscontent15') " />
                                            <span v-html="$t('stats.statscontent16')"> </span>
                                        </span>
                                    </span>
                                </span>
                                <span
                                    class="mx-1 tooltipbutton"
                                    v-else-if="store.traderDetail.trader.stats.badges.phoneVerified || store.traderDetail.trader.stats.badges.addressVerified || store.traderDetail.trader.stats.badges.identificationVerified"
                                >
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon6.png" :alt="$t('stats.statscontent17') " />
                                    <span class="tooltiptext">
                                        <span class="d-block border-bottom pb-2">{{$t('stats.statscontent18')}} </span>
                                        <span class="d-flex mt-2">
                                            <v-lazy-image v-if="store.traderDetail.trader.stats.badges.phoneVerified" class="me-1" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent17')" />
                                            <v-lazy-image v-else class="me-1" src="/assets/images/icon/small-icon/small-t-icon6.png" :alt="$t('stats.statscontent19')" :title="$t('stats.statscontent19')" />
                                            {{$t('stats.statscontent20')}}
                                            <span>{{ store.traderDetail.trader.stats.badges.phoneVerified ? 'Verified' : 'Pending' }}</span>
                                        </span>
                                        <span class="d-flex mt-2">
                                            <v-lazy-image v-if="store.traderDetail.trader.stats.badges.addressVerified" class="me-1" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent17')"/>
                                            <v-lazy-image v-else class="me-1" src="/assets/images/icon/small-icon/small-t-icon6.png" :alt="$t('stats.statscontent21')" :title="$t('stats.statscontent21')" />
                                            {{$t('stats.statscontent21')}}
                                            <span>{{ store.traderDetail.trader.stats.badges.addressVerified ? 'Verified' : 'Pending' }}</span>
                                        </span>
                                        <span class="d-flex mt-2">
                                            <v-lazy-image v-if="store.traderDetail.trader.stats.badges.identificationVerified" class="me-1" src="/assets/images/icon/small-icon/small-t-icon5.png" :alt="$t('stats.statscontent17')"/>
                                            <v-lazy-image v-else class="me-1" src="/assets/images/icon/small-icon/small-t-icon6.png" :alt="$t('stats.statscontent15')" :title="$t('stats.statscontent15')" />
                                            {{$t('stats.statscontent15')}} -
                                            <span>{{ store.traderDetail.trader.stats.badges.identificationVerified ? 'Verified' : 'Pending' }}</span>
                                        </span>
                                    </span>
                                </span>
                                <span class="mx-1" v-if="store.traderDetail.trader.stats.badges.hasTradeHistoryImport">
                                    <v-lazy-image
                                        class="d-block"
                                        src="/assets/images/icon/small-icon/small-t-icon2.png"
                                        :alt="$t('stats.statscontent17')"
                                        :title="'Trading history from ' + formatDate(store.traderDetail.trader.stats.badges.startDateOfImportedTradeHistory) + ' To ' + formatDate(store.traderDetail.trader.stats.badges.endDateOfImportedTradeHistory) + ' has been imported to ZuluTrade'"
                                    />
                                </span>
                                <span class="mx-1" v-if="store.traderDetail.trader.stats.badges.tradingCorrelatedCurrencies">
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon7.png" :alt="$t('stats.statscontent22')" :title="$t('stats.statscontent22')" />
                                </span>
                                <span class="mx-1" v-if="store.traderDetail.trader.stats.badges.tradingEconomicEvents">
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon8.png" :alt="$t('stats.statscontent23')" :title="$t('stats.statscontent23')" />
                                </span>
                                <span class="ms-1 me-4" v-if="store.traderDetail.trader.stats.badges.zuluVeteran">
                                    <v-lazy-image class="d-block" src="/assets/images/icon/small-icon/small-t-icon3.png" :alt="$t('stats.statscontent24')" :title="$t('stats.statscontent24')" />
                                </span>
                            </div>
                            <a href="javascript:void(0)" class="d-flex align-items-center f-12 inactive" @click="translate()"> {{translatedText ? 'Original' : 'Translate'}}</a>
                        </div>
                        <!-- <p>North East Way EA.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="consistently">
                    <p
                        class="mb-0 midgray d-inline"
                        v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.strategyDescApproved == true && store.traderDetail.trader.stats.profile.strategyDesc && store.traderDetail.trader.stats.profile.translated && translatedText"
                        v-html="brDec((show=='less')?((store.traderDetail.trader.stats.profile.translated).slice(0,150)+(store.traderDetail.trader.stats.profile.translated.length > 150?'...':'')):(store.traderDetail.trader.stats.profile.translated))"
                    ></p>
                    <p
                        class="mb-0 midgray d-inline"
                        v-else-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.strategyDescApproved == true && store.traderDetail.trader.stats.profile.strategyDesc"
                        v-html="brDec((show=='less')?((store.traderDetail.trader.stats.profile.strategyDesc).slice(0,150)+(store.traderDetail.trader.stats.profile.strategyDesc.length > 150?'...':'')):(store.traderDetail.trader.stats.profile.strategyDesc))"
                    ></p>
                    <p class="mb-0 d-inline" v-else>{{$t('stats.statscontent25')}}</p>

                    <a
                        v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.strategyDescApproved == true && store.traderDetail.trader.stats.profile.strategyDesc && store.traderDetail.trader.stats.profile.strategyDesc.length > 150"
                        href="javascript:void(0)"
                        class="f-14 medium menutext ms-2"
                        @click="(show != 'less') ? show = 'less' : show = 'more'"
                    >
                        {{ (show != 'less') ? 'show less' : 'show more' }}
                    </a>
                    <p v-if="!store.traderDetail.trader.stats.badges.tradedWithinLastWeek" :title="$t('stats.statscontent26')" class="d-inline-flex bodyBG align-items-center inactive py-1 px-2 mb-0">
                        <i class="gray me-1 fa fa-exclamation-circle" aria-hidden="true"></i> {{$t('stats.statscontent27')}}
                    </p>
                </div>
            </div>
            <div class="col-12">
                <ul
                    class="my-3 d-flex align-items-center equalSpace list-leftBar"
                    v-if="Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length"
                >
                    <li class="ps-0">
                        <h5
                            class="f-20 mb-0"
                            :class="(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].annualizedRorBasedRoi) > 0.0) ? 'green' : 'red'"
                        >
                            {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                            Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].annualizedRorBasedRoi).toFixed(2) : 0}}%
                        </h5>
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="tooltipbutton right0">
                                <span class="inactive f-12 uppercase me-1">{{$t('stats.statscontent28')}}</span>
                                <span class="tooltiptext m-0" v-html="static_vars.roiTooltip"></span>
                            </div>
                        </div>
                    </li>
                    <li :title="static_vars.riskTooltip" v-if="store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].hasOwnProperty('timeFrameRisk')">
                        <h5
                            class="f-20 mb-0 regular"
                            :class="[{'blueBtn bg-white':store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk==1},{'greenBtn bg-white':store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk==2},{'yellowBtn bg-white':store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk==3},{'redBtn bg-white':store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk==4},{'pinkBtn bg-white':store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk==5}]">
                            {{store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk}}
                        </h5>
                        <span class="inactive f-12 uppercase">{{$t('stats.statscontent29')}} </span>
                    </li>
                    <li>
                        <h5
                            class="f-20 mb-0"
                            v-if="store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTrades"
                        >
                            {{store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTrades}}%
                        </h5>
                        <h5 class="f-20 mb-0" v-else>{{$t('stats.statscontent30')}}</h5>
                        <span class="inactive f-12 uppercase">{{$t('stats.statscontent31')}}</span>
                    </li>
                    <li>
                        <h5 class="f-20 mb-0" v-if="store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.followers">{{store.traderDetail.trader.stats.overallStats.followers}}</h5>
                        <h5 class="f-20 mb-0" v-else>{{$t('stats.statscontent32')}}</h5>
                        <span class="inactive f-12 uppercase">{{$t('stats.statscontent33')}} </span>
                        <!-- <h5 class="f-20 mb-0" v-if="store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.followers">{{store.traderDetail.trader.stats.overallStats.followers}}</h5>
                        <span class="inactive f-12 uppercase">INVESTORS </span> -->
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 customHeight">
                <div class="rightTradeChart position-relative">
                    <ul id="tabs-nav" class="zuluTabs d-flex align-items-center mb-3" v-if="$parent.Tab == 3">
                        <li :class="[{ 'active': charttab == 1 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(1)">{{$t('stats.statscontent34')}} </a></li>
                        <li :class="[{ 'active': charttab == 2 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(2)">{{$t('stats.statscontent35')}} </a></li>
                        <li :class="[{ 'active': charttab == 3 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(3)">{{$t('stats.statscontent36')}} </a></li>
                        <!-- <li :class="[{ 'active': charttab == 4 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(4)">{{$t('stats.statscontent37')}} </a></li> -->
                    </ul>
                    <div id="advancedSearch" class="d-block d-sm-flex align-items-center justify-content-between" v-if="$parent.Tab != 4">
                        <ul id="tabs-nav" class="d-flex align-items-center mb-md-0" v-if="$parent.Tab == 3">
                            <li>
                                <a
                                    class="toogleUP"
                                    :class="[{'active' : tradingprofitTab == store.traderDetail.trader.stats.profile.baseCurrencyName}]"
                                    href="javascript:void(0)"
                                    @click="Charttab(store.traderDetail.trader.stats.profile.baseCurrencyName);"
                                >
                                    {{store.traderDetail.trader.stats.profile.baseCurrencyName}}
                                </a>
                            </li>
                            <li><a class="toogleUP" :class="[{'active' : tradingprofitTab == 'Pips'}]" href="javascript:void(0)" @click="Charttab('Pips');">{{$t('stats.statscontent38')}} </a></li>
                        </ul>
                        <!-- <div
                            :class="[{'pairTwo':tab == 2, 'pairOne':tab == 1}]"
                            class="position-relative advancedMenu currencypair"
                            v-if="tab == 1 && store.traderDetail.trader.stats && Object.keys(store.traderDetail.   trader.stats).length && store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.providerCurrencies"
                        >
                            <a href="javascript:void(0)" class="selectDropdown bg-white d-flex align-items-center justify-content-between f-14" @click="currencyPair = !currencyPair">
                                <span class="d-flex align-items-center"><i class="me-1 fa fa-money" aria-hidden="true"></i>{{ selectedCurrency() }}</span> <i class="fa fa-caret-down" aria-hidden="true"></i>
                            </a>
                            <ul class="dropdown_menu_animated week pb-2" :class="[{ 'show': currencyPair }]">
                                <li class="searchDropdown">
                                    <input type="search" placeholder="Filter" class="form-control radiun-0 searchCountry" name="" v-model="currencySearch" />
                                </li>
                                <li v-for="item, index in currencyList" :key="index"><a :class="[{ 'active': currencyFilter[item] == item }]" href="javascript:void(0)" @click="ClickCurrency(item)">{{ item }}</a></li>
                            </ul>
                        </div> -->
                    </div>

                    <div class="bar-chart position-relative border-bottom pb-4 mb-3" v-if="$parent.Tab == 1">
                        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                        <div class="chartsTag newFillTabs">
                            <div class="proChart">
                                <div id="overAllChart" style="height: 300px; width: 100%;" class="overAllChart"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Trades v-if="$parent.Tab == 2" ref="trading" />
                <AdvancedStats v-if="$parent.Tab == 3" ref="advancedchart" />
                <RealInvestor v-if="$parent.Tab == 4" ref="realInvestor" />
            </div>
            <ul class="d-flex align-items-center justify-content-evenly" v-if="($parent.Tab == 1) || ($parent.Tab == 3)">
                <li v-for="list in dateDrop" :key="list.key">
                    <a class="f-14 gray selectDay" @click.prevent="dateDropSelected = list;callDashboard()" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                </li>
            </ul>
            <div class="col-12" v-if="$parent.Tab == 1">
                <div class="statisticsOverall">
                    <p class="medium">{{$t('stats.statscontent39')}}</p>
                    <ul
                        class="overallList d-flex align-items-center flex-wrap justify-content-center mb-0"
                        v-if="store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length"
                    >
                        <li>
                            <span class="inactive f-12 uppercase">{{$t('stats.statscontent40')}} </span>
                            <!-- <h5 v-if="tradingprofitTab=='Pips'" class="f-20 regular" :class="(parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney) > 0.0) ? 'green' : 'red'">{{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney).toFixed(2) : 0}} pips</h5> -->
                            <h5
                                class="f-20 regular"
                                :class="(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney) > 0.0) ? 'green' : 'red'"
                            >
                                {{COMMON.getBaseCurrencySymbol()}}{{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney).toFixed(2) : 0}}
                            </h5>
                        </li>
                        <li>
                            <span class="inactive f-12 uppercase" :title=" $t('stats.statscontent41') ">{{$t('stats.statscontent42')}} </span>
                            <h5 class="f-20 regular">
                                {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].trades : 0}}
                            </h5>
                        </li>
                        <li>
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent43') ">
                                {{$t('stats.statscontent44')}}
                            </span>
                            <h5 class="f-20 regular">
                                {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].maxOpenTrades : 0}}
                            </h5>
                        </li>
                        <li v-if="$refs.profit?.tradingprofitTab=='Pips'">
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent45') ">{{$t('stats.statscontent46')}} </span>
                            <h5 class="f-20 regular">
                                {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].avgPipsPerTrade).toFixed(2) : 0}}
                            </h5>
                        </li>
                        <li v-else>
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent47') ">{{$t('stats.statscontent48')}} </span>
                            <h5 class="f-20 regular">
                                {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].profitPercentage).toFixed(2) : 0}}%
                            </h5>
                        </li>
                    </ul>
                    <ul class="overallList d-flex align-items-center flex-wrap justify-content-center mb-0">
                        <li>
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent49') ">{{$t('stats.statscontent50')}} </span>
                            <h5 class="f-20 regular" :title="$t('stats.statscontent51') ">
                                <h5 class="f-20 regular">
                                    ({{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTradesCount : 0}})
                                    <sub>
                                        {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                        Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTrades).toFixed(2) : 0}}%
                                    </sub>
                                </h5>
                            </h5>
                        </li>
                        <li>
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent52') ">
                                {{$t('stats.statscontent53')}}
                            </span>

                            <h5 class="f-20 regular">
                                {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].recommendedMinAmount.htmlSymbol :
                                '$'}}{{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].recommendedMinAmount.amount : 0}}
                            </h5>
                        </li>
                        <li>
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent54') ">{{$t('stats.statscontent55')}} </span>
                            <h5 v-if="$refs.profit?.tradingprofitTab=='Pips'" class="f-20 regular">
                                -{{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].maxDrawDown).toFixed(2) :
                                0}}{{$t('stats.statscontent56')}}
                            </h5>
                            <h5 v-else class="f-20 regular">
                                (${{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].overallDrawDownMoney).toFixed(2) :
                                0}})
                                <sub>
                                    {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ?
                                    parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].overallDrawDownMoneyPercent).toFixed(2) : 0}}%
                                </sub>
                            </h5>
                        </li>
                        <li>
                            <span class="inactive f-12 uppercase" :title="$t('stats.statscontent60')">{{$t('stats.statscontent57')}}</span>
                            <h5 class="f-20 regular">
                                {{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].avgTradesPerWeek : 0}}
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
    <loginPopup v-if="showLoginPopup"></loginPopup>
    <!--  <copyStrategy v-if="copyStrategy"></copyStrategy> -->
</template>
<script>
    import $ from "jquery";
    //import Profit from "@/components/charts/trader-profit";
    //import Trading from "@/views/trader/trading/trading";
    //import Social from "@/views/trader/social/social.vue";
    import RealInvestor from "@/views/trader/real-investors/real-investors.vue";
    import AdvancedStats from "@/views/trader/advanced-stats/leader-advanced.vue";
    import { myStore } from "@/store/pinia-store";
    import moment from "moment";
    // import copyStrategy from "./copy-strategy/copy-strategy";
    import sharePop from "@/views/social-feed/share-pop";
    //import LeadersFeed from "@/store/leader-stomp";
    import Trades from "../../trades.vue";
    // import Performance from "@/components/charts/dashboard/performance";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                tab: 2,
                currencyPair: false,
                datePeriod: false,
                dateDrop: [
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "All" },
                ],
                dateDropSelected: { key: 10000, value: "All" },
                currencyFilter: {},
                currencySearch: "",
                followerInfo: {},
                searchTrder: "",
                show: "less",
                tradingprofitTab: "",
                charttab: 1,
                divHeigh: "",
                showLoginPopup: false,
                copyStrategy: false,
                sharePop: "",
                translatedText: false,
            };
        },
        components: {
            // RealInverstors
            // Performance,
            //Trading,
            //Social,
            AdvancedStats,
            // copyStrategy,
            Trades,
            sharePop,
            RealInvestor,
        },
        computed: {
            currencyList() {
                if (
                    this.store.traderDetail.trader.stats &&
                    Object.keys(this.store.traderDetail.trader.stats).length &&
                    this.store.traderDetail.trader.stats.overallStats &&
                    this.store.traderDetail.trader.stats.overallStats.providerCurrencies
                ) {
                    if (this.currencySearch) {
                        return this.store.traderDetail.trader.stats.overallStats.providerCurrencies.split(",").filter((item) => item.toLowerCase().includes(this.currencySearch.toLowerCase()));
                    } else {
                        return this.store.traderDetail.trader.stats.overallStats.providerCurrencies.split(",");
                    }
                } else {
                    return [];
                }
            },
            getAccountWiseCopyLeaders() {
                let data = [];
                if (this.store.allTypeAccount && this.store.allTypeAccount == "ALL_LIVE") {
                    data = this.userTradingAccountsList.filter(i => i.demo === false);
                } else {
                    if (Object.keys(this.store.userSelectedAccount).length) {
                        data = this.userSelectedAccount;
                    } else {
                        data = [];
                    }
                }
                return data;
            },
        },
        watch: {
            "store.userDashboardDetail"() {
                if (this.$parent.Tab == 1 && Object.keys(this.store.userDashboardDetail).length && this.store.userDashboardDetail.performanceChart && this.store.userDashboardDetail.performanceChart.length) {
                    this.drawOverAllChart("overAllChart", this.store.userDashboardDetail.performanceChart);
                }
            },
            "store.traderDetail"() {
                if (Object.keys(this.store.traderDetail).length > 0) {
                    setTimeout(() => {}, 300);
                }
            },
            "$parent.Tab"() {
                if (this.$parent.Tab == 1) {
                    setTimeout(() => {
                        this.drawOverAllChart("overAllChart", this.store.userDashboardDetail.performanceChart);
                    }, 300);
                }
            },
            "store.userSelectedAccount"(){
                if(this.store.user.access_token && this.store.userSelectedAccount.isLeader && this.store.userSelectedAccount.providerStatus=='APPROVED'){
                    this.calltraderDetail()
                }
            },
            'store.allTypeAccount'(){
                if(this.store.user.access_token && this.store.userSelectedAccount.isLeader && this.store.userSelectedAccount.providerStatus=='APPROVED' && this.store.allTypeAccount){
                    this.calltraderDetail()
                }
            }
        },
        methods: {
            callDashboard() {
                if (this.$parent.Tab == 1) {
                    this.store.getUserDashboardData({}, true, "", this.dateDropSelected.key);
                }
            },
            drawOverAllChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                let root = am5.Root.new(id);
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

                // root.dateFormatter.setAll({
                //     dateFormat: "dd MMM yyyy",
                //     dateFields: ["valueX"],
                // });
                root.numberFormatter.set("numberFormat", "#,###.00");
                // Create chart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        groupData: true,
                        strictMinMax: true,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        // tooltip: am5.Tooltip.new(root, {})
                    })
                );
                xAxis.get("dateFormats")["day"] = "dd MMM";
                xAxis.get("periodChangeDateFormats")["day"] = "YYYY";
                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                // Add series
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            // pointerOrientation: "horizontal",
                            labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]Equity[/] : {valueY}",
                        }),
                    })
                );

                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [{ opacity: 0.2 }, { opacity: 0.1 }],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["x"],
                });
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // chart.zoomOutButton.set("forceHidden", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });

                // Set data
                series.data.setAll(data);

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(500, 100);
            },
            changeChartTab(val) {
                this.charttab = val;
            },
            Charttab(type) {
                this.tradingprofitTab = type;
            },
            ClickCurrency(item) {
                if (this.currencyFilter[item]) {
                    delete this.currencyFilter[item];
                } else {
                    this.currencyFilter[item] = item;
                }
                if (this.$parent.Tab == 1) {
                    this.$refs.profit.getTradingProfitChartData();
                } else if (this.$parent.Tab == 2) {
                    setTimeout(() => {
                        this.$refs.trading.pageListing();
                    }, 300);
                }
            },
            selectedCurrency() {
                if (Object.values(this.currencyFilter).length) {
                    return Object.values(this.currencyFilter).length + " currency";
                } else {
                    return "Any currency pair";
                }
            },
            nextUser(userid) {
                this.$parent.Tab = 1;
                this.show = "less";
                if (userid) {
                    let path = `/trader/${userid}/trading?t=10000&m=1`;
                    this.$router.replace(path);
                    this.searchTrder = "";
                }
            },
            formatDate(date) {
                if (date) {
                    let d = moment(date);
                    let tz = new Date().getTimezoneOffset();
                    d.add(tz, "minutes");
                    return moment(d).format("YYYY/MM/DD HH:mm");
                }
            },
            changeRoute() {
                let path = "/trader/" + this.$route.params.id + "/trading";
                this.$router.push({ path: path, query: { t: this.dateDropSelected.key, m: 1 } });
            },
            getCopyStats(id) {
                this.store.getCopyStats({}, false, id);
            },
            calltraderDetail() {
                this.store.calltraderDetail({}, true, this.returnProviderID(), this).then(() => {
                    this.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName;
                });
            },
            returnProviderID(){
                if(Object.keys(this.store.userSelectedAccount).length){
                    return this.store.userSelectedAccount.providerId
                } else {
                    let list = this.store.userTradingAccountsList.filter(i=>(i.demo === false && i.isLeader && i.providerStatus=='APPROVED'))
                    return list[0].providerId
                }
            },
            calltoFiveFollowers() {
                this.store.getTopFiveFollowersList({}, false, this.$route.params.id);
            },
            callCurrencyPairsList() {
                if (
                    this.store.traderDetail.trader &&
                    Object.keys(this.store.traderDetail.trader).length &&
                    this.store.traderDetail.trader.stats &&
                    this.store.traderDetail.trader.stats.profile &&
                    this.store.traderDetail.trader.stats.profile.feedGroupId
                ) {
                    this.store.callCurrencyPairsList({}, false, this.store.traderDetail.trader.stats.profile.feedGroupId);
                }
            },
            brDec(text) {
                let br = text.replace(/[\r\n]/g, "<br>");
                return br;
                // if (br.length > 120) {
                // if (this.show == "less") {
                //     return br
                // } else {
                //     return br;
                // }
                // } else {
                //     return br;
                // }
            },
            translate() {
                if (this.translatedText) {
                    this.translatedText = false;
                } else {
                    this.translatedText = true;
                    if (this.store.traderDetail.trader.stats.profile && this.store.traderDetail.trader.stats.profile.strategyDescApproved && this.store.traderDetail.trader.stats.profile.strategyDesc) {
                        this.store.socialTranslate({ text: this.store.traderDetail.trader.stats.profile.strategyDesc, to: "en" }, false, this.store.traderDetail.trader.stats.providerId, "leaderProfile");
                    }
                }
            },
        },
        mounted() {
            let _that = this;
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".dateperiod").length) {
                    _that.datePeriod = false;
                }
                if (!$(event.target).closest(".currencypair").length) {
                    _that.currencyPair = false;
                }
            });
            $(function () {
                var shrinkHeader = 60;
                $(window).scroll(function () {
                    var scroll = getCurrentScroll();
                    if (scroll >= shrinkHeader) {
                        $(".sndHeader").addClass("bg-sticky");
                    } else {
                        $(".sndHeader").removeClass("bg-sticky");
                    }
                });
                function getCurrentScroll() {
                    return window.pageYOffset || document.documentElement.scrollTop;
                }
            });
        },
        updated() {
            this.divHeigh = $(".div-height").height() + 30;
        },
        created() {
            this.calltraderDetail();
        },
    };
</script>
<style>
    #overAllChart > div {
        display: none;
    }
    #overAllChart > div:last-child {
        display: block !important;
    }
</style>
