<template>
    <div class="homeLeader mb-4 h-100">
        <div class="row">
            <div class="col-12 col-lg-3 setHeight">
                <a v-if="getAccountWiseCopyLeaders.length > defaultItems" :class="[{'d-none':!(counter > 0)}]" class="scrollButton up" href="javascript:void(0)" @click="scrollLeader('t')"> <vue-feather type="chevron-up"></vue-feather> </a>
                <!-- :style="{'height':maxHeight+8 + 'px'}" -->
                <div id="leadersContainer" v-if="getAccountWiseCopyLeaders.length">
                    <div class="card shadow-none mb-2" :class="[{'active':this.$parent.providerClass==item.providerId}]"  v-for="item,key in getAccountWiseCopyLeaders" :key="key">
                        <div class="card-body pointer" v-if="item.providerId != -1" @click="advanceStats(item.providerId)">
                            <div class="text-center position-relative">
                                <div class="d-flex align-items-center justify-content-between flex-wrap lead_aer">
                                    <div class="d-flex align-items-center leaderAvtar">
                                        <span class="circleImg d-inline-block me-2">
                                            <v-lazy-image
                                                v-if="item.providerId"
                                                width="40"
                                                height="40"
                                                class="rounded-circle"
                                                :src="static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XL&id=' + item.providerId + '&ignore=false'"
                                                :alt="item.providerName"
                                                :title="item.providerName"
                                            />
                                            <v-lazy-image v-else width="40" height="40" class="rounded-circle" src="/assets/images/profile-pic.png" :alt="$t('copyTrade.copyTradecontent21')" :title="$t('copyTrade.copyTradecontent21')" />
                                        </span>
                                        <div class="text-start">
                                            <a class="d-block medium f-15" href="javascript:void(0)">{{item.providerName}}</a>
                                            <!-- <p class="line-1 mb-0 midgray f-13 medium">@rever273</p> -->
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <!-- <p class="bg-small f-12 mb-0 medium"><span class="menutext">{{openTrades(item.providerId)}}</span> Open trades</p> -->
                                        <div class="position-absolute fixdTrde ms-3">
                                            <a class="ativeLink showClass" href="javascript:void(0)" @click.stop="showUpdate(item)">
                                                <vue-feather class="gray" type="more-vertical" size="18"></vue-feather>
                                            </a>
                                            <ul class="dropdown-menu show" data-popper-placement="bottom-start" v-if="openDrop==item.providerId">
                                                <li v-if="!item.capitalProtectionAmount"><a class="f-12 dropdown-item" href="javascript:void(0);" :class="{'disabled' : store.customerDetail?.readOnly}" @click.stop="stopStrat(item.providerId,item.zuluAccountId)">{{$t('copyTrade.copyTradecontent1')}}</a></li>
                                                <li v-else><a class="f-12 dropdown-item" href="javascript:void(0);" :class="{'disabled' : store.customerDetail?.readOnly}" @click.stop="getInvestedAmount()">{{$t('copyTrade.copyTradecontent2')}}</a></li>
                                                    <!-- <li v-if="!item.capitalProtectionAmount && !item.takeProfitReached"><a class="f-12 dropdown-item" href="javascript:void(0);" @click.stop="updateStategy(item)">{{$t('copyTrade.copyTradecontent3')}}</a></li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="lead_aqw mt-4 pointer">
                                    <!--v-if="item.capitalProtectionAmount"-->
                                    <div class="collectValue text-start">
                                        <div class="interLive">
                                        <p class="line-1 mb-1 midgray f-12">{{$t('copyTrade.copyTradecontent4')}}{{parseFloat(item.capitalProtectionAmount).toLocaleString('en') || 0}}</p>
                                            <div class="d-flex align-items-center">
                                                <h3 class="mb-0 bold f-24 bold me-2">{{parseFloat(COMMON.realTimeLeadersPnl(item)) > 0.00 ? '' : '-'}}{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{parseInt(Math.abs(COMMON.realTimeLeadersPnl(item) || 0)).toLocaleString('en')}}.<sub>{{((parseFloat(COMMON.realTimeLeadersPnl(item)).toFixed(2)) + "").split(".")[1]}}</sub></h3>
                                                <!-- <h3 class="mb-0 bold f-24 bold me-2" v-else>{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}0.00</h3> -->
                                                <p class="mb-0 status bordered bold f-12" :class="parseFloat(COMMON.realTimeGrossROILeaders(item))>=0?'green':'red'">{{parseFloat(COMMON.realTimeGrossROILeaders(item) || 0).toFixed(2)}}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lead_aqw ZuluGuard position-absolute bg-card br-6 text-start p-2" v-if="!item.capitalProtectionAmount && !item.takeProfitReached">
                                    <!-- <a title="Remove ZuluGuard" class="position-absolute close red" href="javascript:void(0)"><vue-feather type="x-circle" size="14"></vue-feather> </a> -->
                                    <h6 class="d-flex align-items-center mb-1"><v-lazy-image width="14" class="me-1 d-block" src="/assets/images/ZuluguardNew.svg" :alt="$t('copyTrade.copyTradecontent20')" />{{$t('copyTrade.copyTradecontent5')}}</h6>
                                    <p class="midgray line-1 f-12 mb-0 singleLine" :title="$t('copyTrade.copyTradecontent15')">{{$t('copyTrade.copyTradecontent6')}}</p>
                                    <router-link :to="{name: 'autoprotectAccount'}" class="secondary f-12 bold">{{$t('copyTrade.copyTradecontent10')}}</router-link>
                                </div>
                                <div class="lead_aqw ZuluGuard position-absolute bg-card br-6 text-start p-2" v-if="!item.capitalProtectionAmount && item.takeProfitReached">
                                    <!-- <a title="Remove ZuluGuard" class="position-absolute close red" href="javascript:void(0)"><vue-feather type="x-circle" size="14"></vue-feather> </a> -->
                                    <h6 class="d-flex align-items-center mb-1"><v-lazy-image width="14" height="14" class="me-1 d-block" src="/assets/images/zuluGuard-b.svg" :alt="$t('copyTrade.copyTradecontent20')" />{{$t('copyTrade.copyTradecontent8')}}</h6>
                                    <p class="midgray line-1 f-12 mb-0 singleLine" :title="$t('copyTrade.copyTradecontent16')">{{$t('copyTrade.copyTradecontent9')}}</p>
                                    <router-link :to="{name: 'autoprotectAccount'}" class="secondary f-12 bold">{{$t('copyTrade.copyTradecontent10')}} </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3 text-center" v-if="!getAccountWiseCopyLeaders.length">
                    <div class="nodataError text-center">
                        <div class="card shadow-none mb-2">
                            <div class="card-body p-0">
                                <v-lazy-image class="d-block w-100" src="/assets/images/loader-animate.svg" :alt="$t('copyTrade.copyTradecontent18')" />
                            </div>
                        </div>
                        <p class="midgray" v-html="$t('copyTrade.copyTradecontent11')"></p>
                    </div>
                </div>
                <a v-if="getAccountWiseCopyLeaders.length > defaultItems" :class="[{'d-none':!(counter < this.getAccountWiseCopyLeaders.length-this.defaultItems)}]" class="scrollButton" href="javascript:void(0)" @click="scrollLeader('b')"> <vue-feather type="chevron-down"></vue-feather> </a>
            </div>
            <div class="col-12 col-lg-9">
                <div ref="getHeight">
                    <!----------- chart------------>
                    <div class="LeaderStats position-relative minChart p-3 bg-white modalhead br-20 mb-4">
                        <!-- <h4 class="f-22 bold mb-3">{{$t('copyTrade.copyTradecontent14')}}</h4> -->
                        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                        <div class="LeaderStats" v-else-if="Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length && $parent.providerClass != -1">
                            <div class="statsHead d-flex align-items-center justify-content-between mb-1 p-2">
                                <div class="d-flex align-items-center leaderAvtar">
                                    <router-link class="d-flex align-items-center" :to="`/trader/${store.traderDetail.trader.stats.providerId}/trading?t=10000&m=1`">
                                        <!-- <span class="circleImg d-inline-block me-2">
                                            <v-lazy-image
                                                :src="static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XL&id=' + store.traderDetail.trader.stats.providerId + '&ignore=false'"
                                                :alt="store.traderDetail.trader.stats.profile.name"
                                                :title="store.traderDetail.trader.stats.profile.name"
                                            />
                                        </span> -->
                                        <div class="text-start">
                                            <a class="d-block medium mb-1 f-18" href="javascript:void(0)">{{ store.traderDetail.trader.stats.profile.name || ''}} Statistics</a>
                                            <!-- <p class="line-1 mb-0 midgray f-14 medium">@rever273</p> -->
                                        </div>
                                    </router-link>
                                </div>
                                <a href="javascript:void(0)" class="close round" @click="emptyStats()"><vue-feather size="20" type="x"></vue-feather> </a>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="chartsTag newFillTabs">
                                        <div class="proChart mt-0" v-if="Object.keys(store.userChartWithLeader).length && store.userChartWithLeader.series && store.userChartWithLeader.series.length">
                                            <Performance :chartData="store.userChartWithLeader.series" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nodataError d-flex align-items-center justify-content-center flex-column"
                            v-if="!store.singleLoading && $parent.providerClass != -1 && !Object.keys(store.traderDetail).length"
                        >
                            <v-lazy-image width="80" height="80" class="mb-2" src="/assets/images/nodataError.png" :alt="$t('copyTrade.copyTradecontent18')" />
                            <p class="midgray">{{$t('no_data.noData')}}</p>
                        </div>
                        <div class="tradeInfo" v-show="!store.singleLoading && $parent.providerClass == -1 && Object.keys(store.userDashboardDetail).length && store.userDashboardDetail.performanceChart && store.userDashboardDetail.performanceChart.length">
                            <div class="chartsTag newFillTabs">
                                <div class="proChart mt-0">
                                    <div id="overAllChart" style="height: 300px; width: 100%;" class="overAllChart"></div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nodataError d-flex align-items-center justify-content-center flex-column"
                            v-if="!store.singleLoading && $parent.providerClass == -1 && Object.keys(store.userDashboardDetail).length && store.userDashboardDetail.performanceChart && !store.userDashboardDetail.performanceChart.length"
                        >
                            <!-- <v-lazy-image width="80" class="mb-2" src="/assets/images/nodataError.png" alt="Nodata" />
                            <p class="midgray">{{$t('no_data.noData')}}</p> -->
                            <v-lazy-image class="light" src="/assets/images/GRAPHUpcoming.png" :alt="$t('copyTrade.copyTradecontent22')" :title="$t('copyTrade.copyTradecontent12') "/>
                            <v-lazy-image class="dark d-none" src="/assets/images/darkUpcoming.png" :alt="$t('copyTrade.copyTradecontent22')" :title="$t('copyTrade.copyTradecontent12') "/>
                        </div>
                        <div class="text-center">
                            <ul class="d-inline-flex align-items-center justify-content-center activelight mb-0 br-10 p-2">
                                <li v-for="list in dateDrop" :key="list.key">
                                    <a class="f-15 gray py-1 selectDay" @click.prevent="dateDropSelected = list;CallDahboardData(dateDropSelected)" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tradeMain">
                        <Trades />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <copyStrategy v-if="copyStrategy"></copyStrategy>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { useToast } from "vue-toastification";
    // import { Carousel, Slide, Navigation } from "vue3-carousel";
    import Performance from "@/components/charts/dashboard/performance";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    import copyStrategy from "../copy-strategy";
    import Trades from "../../trades.vue";
    export default {
        setup() {
            const store = myStore();
            const toast = useToast();
            return { store, toast };
        },
        data() {
            return {
                currencySign: '',
                isExpand: false,
                openDrop: "",
                leaderData: {},
                copyStrategy: false,
                Leaderjson : {},
                
                dateDrop: [
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "All" },
                ],
                dateDropSelected: { key: 10000, value: "All" },
                maxHeight: '',
                counter: 0,
                defaultItems: 5
            };
        },
        components: {
            Performance,
            copyStrategy,
            Trades,
        },
        watch: {
            "store.userDashboardDetail"() {
                if (Object.keys(this.store.userDashboardDetail).length && this.store.userDashboardDetail.performanceChart && this.store.userDashboardDetail.performanceChart.length) {
                    this.drawOverAllChart("overAllChart", this.store.userDashboardDetail.performanceChart);
                }
            },
        },
        computed: {
            getAccountWiseCopyLeaders() {
                if (Object.keys(this.store.copyTraders).length && this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                    let data = [];
                    if (this.store.allTypeAccount) {
                        if (this.store.allTypeAccount == "ALL_LIVE") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === false);
                        } else if (this.store.allTypeAccount == "ALL_DEMO") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === true);
                        }
                    } else {
                        if (Object.keys(this.store.userSelectedAccount).length) {
                            data = this.store.copyTraders.providers.filter((i) => i.zuluAccountId == this.store.userSelectedAccount.zuluAccountId);
                        } else {
                            data = [];
                        }
                    }
                    return data;
                } else {
                    return [];
                }
            },
        },
        methods: {
            scrollLeader(type){
                let theDiv = document.getElementById("leadersContainer")
                if(type=='b' && this.counter < this.getAccountWiseCopyLeaders.length-this.defaultItems){
                    this.counter++
                    let px = 164 * this.counter
                    theDiv.scrollTo({
                        top: px,
                        behavior: 'smooth'
                    });
                } else if(type=='t' && this.counter > 0) {
                    this.counter--
                    let px = 164 * this.counter
                    theDiv.scrollTo({
                        top: px,
                        behavior: 'smooth'
                    });
                }
            },
            getTime(){
                var date = new Date();
                return date.getTime()
            },
            getWatchListCount() {
                if (this.store.watchList.length) {
                    let data = this.store.watchList.filter((i) => i.type == "LEADER");
                    if (data.length) {
                        return data.length;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
            updateStategy(item) {
                let blockedIDs = ["371351", "371319", "371318", "371602", "371197"];
                if (!blockedIDs.includes(item.providerId)) {
                    let form = {
                        capitalProtectionAmount: 0, // The amount to invest
                        capitalProtectionModeId: item.capitalProtectionTrailing ? 1 : 2, 
                        providerId: item.providerId, // The leader's id
                        stopOnProfit: item.stopOnProfit, // for take profit value,
                    };
                    if (!isNaN(item.prorataPercent) && parseFloat(item.prorataPercent) > 0) {
                        form["prorataPercent"] = item.prorataPercent;
                    }
                    this.store.updateLeader(form, true, item.zuluAccountId, false,this).then(() => {
                        this.getCopyLeaders()
                    });
                }
            },
            getCopyLeaders(){
                this.store.GetCopyTraders({},false)
                // this.store.getCopyStats({},false,this.$parent.leaderData.zuluAccountId)
            },
            stopStrat(providerID, zuluaccountID) {
                this.store.stopStategy({}, true, providerID, zuluaccountID, "removeStrategy","yes",this).then(() => {
                    if (Object.keys(this.store.userSelectedAccount).length && this.store.userSelectedAccount.zuluAccountId) {
                        this.store.GetCopyTraders({},false)
                        // this.store.getCopyStats({}, false, this.store.userSelectedAccount.zuluAccountId);
                    }
                });
            },
            CallDahboardData(data) {
                if(this.$parent.providerClass == -1){
                    this.store.getUserDashboardData({}, true, "", data.key);
                }else{
                    let form = {};
                    form['pid'] = this.$parent.providerClass
                    form['type'] = 'PROFIT_PNL'
                    form['timeframe'] = this.dateDropSelected.key
                    form['timespan'] = 'MONTHLY'
                    this.store.getUserChartWithLeader(form,true)
                }
            },
            getInvestedAmount() {
                this.store.GetCopyTraders({}, false,).then((response) => {
                    if (Object.keys(response).length && response.providers && response.providers.length) {
                        this.copyStrategy = true;
                    }
                });
            },
            showUpdate(item) {
                if (this.openDrop != item.providerId) {
                    this.openDrop = item.providerId;
                    this.leaderData = item;
                } else {
                    this.openDrop = "";
                }
            },
            advanceStats(id) {
                this.store.$patch({ traderDetail: [] });
                this.store.$patch({ tradingPerformanceChart: [] });
                this.openDrop = "";
                this.leaderData = {};
                if (id && id != -1) {
                    if (this.$parent.providerClass != id) {
                        this.$parent.providerClass = id;
                        this.store.calltraderDetail({}, true, id, this).then(() => {
                            let form = {};
                            form['pid'] = this.$parent.providerClass
                            form['type'] = 'PROFIT_PNL'
                            form['timeframe'] = this.dateDropSelected.key
                            form['timespan'] = 'DAILY'
                            this.store.getUserChartWithLeader(form,true)
                        });
                        } else {
                            this.emptyStats();
                        }
                } else {
                    this.$parent.providerClass = -1;
                    if (Object.keys(this.store.userDashboardDetail).length && this.store.userDashboardDetail.performanceChart && this.store.userDashboardDetail.performanceChart.length) {
                        this.drawOverAllChart("overAllChart", this.store.userDashboardDetail.performanceChart);
                    }
                }
            },
            emptyStats() {
                this.store.$patch({ traderDetail: [] });
                this.store.$patch({ tradingPerformanceChart: [] });
                this.$parent.providerClass = -1;
            },
            drawOverAllChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                let root = am5.Root.new(id);
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

                // root.dateFormatter.setAll({
                //     dateFormat: "dd MMM yyyy",
                //     dateFields: ["valueX"],
                // });
                root.numberFormatter.set("numberFormat", "#,###.00"); 
                // Create chart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        groupData: true,
                        strictMinMax: true,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        // tooltip: am5.Tooltip.new(root, {})
                    })
                );
                xAxis.get("dateFormats")["day"] = "dd MMM";
                xAxis.get("periodChangeDateFormats")["day"] = "YYYY";
                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                // Add series
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            // pointerOrientation: "horizontal",
                             labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]Equity[/] : {valueY}"
                        }),
                    })
                );

                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [ { opacity: 0.2, }, { opacity: 0.1, }, ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["x"],
                });
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // chart.zoomOutButton.set("forceHidden", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });

                // Set data
                series.data.setAll(data);

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(500, 100);
            },
        },
        updated(){
            this.maxHeight = this.$refs.getHeight.clientHeight
        },
        mounted() {
            if (Object.keys(this.store.userDashboardDetail).length && this.store.userDashboardDetail.performanceChart && this.store.userDashboardDetail.performanceChart.length) {
                am5.ready(() => {
                    this.drawOverAllChart("overAllChart", this.store.userDashboardDetail.performanceChart);
                    this.currencySign = this.COMMON.getBaseCurrencySymbol()
                });
            }
        },
        created() {
            //PriceFeed.getInstance(`wss://www.zulutrade.com:443/api/ws/zulutradewsclient/websocket?access_token=${this.store.user.access_token}`, `/topic/feed/group/1000`).activate();
            this.store.$patch({ dashboardTradesTab: "open" });
            // if(Object.keys(this.store.userSelectedAccount).length && this.store.userSelectedAccount.zuluAccountId){
            //     this.store.getUserDashboardData({},true,this.store.userSelectedAccount.zuluAccountId)
            //     this.store.getCopyStats({},false,this.store.userSelectedAccount.zuluAccountId)
            // }
            //this.tradesTypeData()
        },
    };
</script>
<style>
    #overAllChart>div {
        display: none;
    }
    #overAllChart>div:last-child {
        display: block !important;
    }
</style>
