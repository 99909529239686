<template>
    <!-- <Loader v-if="store.singleLoading"></Loader> -->
    <div id="advancedSearch" class="text-end insideGraph">
        <div class="advancedMenu">
            <a href="javascript:void(0)" class="selectDropdown min-auto bg-white f-14 d-flex align-items-center justify-content-between" @click="performance = !performance" style="width:210px">{{performanceFilter}}<i class="ms-2 fa fa-caret-down" aria-hidden="true"></i></a>
            <ul class="dropdown_menu_animated week py-2" :class="[{'show':performance}]">
                <li v-for="value,key in static_vars.tradingPerformaceFilterJson" :key="key">
                    <a @click="performanceFilter=value;performance=false;getTradingPerformanceChartData()" :class="[{'active':performanceFilter == value}]" href="javascript:void(0)">{{value}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div id="tradingperformanceDashboardChart" class="tradingperformanceChart" v-show="Object.keys(store.tradingPerformanceChart).length && store.tradingPerformanceChart.series && store.tradingPerformanceChart.series.length && store.tradingPerformanceChart.series[0].data.length"></div>
     <div class="nodataStructure" v-if="!store.singleLoading && Object.keys(store.tradingPerformanceChart).length && store.tradingPerformanceChart.series && (!store.tradingPerformanceChart.series.length || !store.tradingPerformanceChart.series[0].data.length)">
     <Nodata></Nodata></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return{
            performance : false,
            performanceFilter : 'Include Unrealized PnL',
            performancelabeljson : {
                'Include Unrealized PnL' : {
                    0 : 'Total PnL',
                    1 : 'Unrealised PnL'
                },
                'Closed Trades Only' : {
                    0 : this.$parent.$parent.tradingprofitTab != 'Pips' ? this.store.traderDetail.trader.stats.profile.baseCurrencyName : 'Pips'
                },
                'Trade Volume' : {
                    0 : 'Win',
                    1 : 'Lose',
                    2 : 'Break-Even'
                },
                'Best-Worst' : {
                    0 : 'Best Trade',
                    1 : 'Worst Trade'
                },
                'Ranking' : {
                    0 : 'Ranking'
                }
            }
        }
    },
     watch : {
        '$parent.$parent.dateDropSelected'(){
            this.getTradingPerformanceChartData()
        },
        '$parent.$parent.tradingprofitTab'(){
             this.getTradingPerformanceChartData()
        }
    },
    methods: {
        drawPerformanceChart(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'tradingperformanceDashboardChart');
            d?.dispose();
            var root = am5.Root.new("tradingperformanceDashboardChart");
            // Set themes
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root),
            ]);

            // Create chart
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                paddingLeft: 0,
                paddingRight: 0,
            }));

            // Add cursor
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            // Create axes
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0,
                baseInterval: {
                    timeUnit: "month",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                // tooltip: am5.Tooltip.new(root, {})
            }));
            var label2 = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );
            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            let json = {};
            if(this.performanceFilter == 'Trade Volume'){
                json = {
                    rotation: -90,
                    y: am5.p50,
                    text: "[#666] Number of Trades [/]  ",
                    centerX: am5.p50,
                    fontSize: 12,
                }
            }else{
                if(this.$parent.$parent.tradingprofitTab == this.store.traderDetail.trader.stats.profile.baseCurrencyName){
                    json = {
                        rotation: -90,
                        y: am5.p50,
                        text: this.store.traderDetail.trader.stats.profile.baseCurrencyName,
                        fill: am5.color(0x666666),
                        centerX: am5.p50,
                        fontSize: 12,
                    }
                }else if(this.$parent.$parent.tradingprofitTab == 'Pips'){
                    json = {
                        rotation: -90,
                        y: am5.p50,
                        text: "[#666] Pips [/]  ",
                        centerX: am5.p50,
                        fontSize: 12,
                    }
                }
            }
            var label1 = am5.Label.new(root, json)
            yAxis.children.unshift( label1 );
            
            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });

            chart.get("colors").set("colors", [
                am5.color(0xff7200),
                am5.color(0x4572a7),
                am5.color(0xcbcbcb),
            ]);
            var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });
           this.store.tradingPerformanceChart.series.forEach((val,index) =>{
            if(val.data.length){
                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    // legendLabelText: this.performancelabeljson[this.performanceFilter][index],
                    legendLabelText: this.performanceFilter=='Closed Trades Only' ? (this.$parent.$parent.tradingprofitTab=='Pips' ? 'Pips' : this.store.traderDetail.trader.stats.profile.baseCurrencyName) : this.performancelabeljson[this.performanceFilter][index],
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueX.formatDate('dd MMM yyyy')}:{valueY}",
                        fontSize: 13,
                    })
                }));
                var data = val.data
                series.data.setAll(data);
                legend.data.push(series);

                series.appear(1000);
            }
           })
            
            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });

            // Make stuff animate on load
            chart.appear(1000, 100);
        },
        drawPairsPerformanceChart(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'tradingperformanceDashboardChart');
            d?.dispose();
            var root = am5.Root.new("tradingperformanceDashboardChart");
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root),
            ]);
            // Create chart
                var chart = root.container.children.push(am5xy.XYChart.new(root, {
                   panX: false,
                    panY: false,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    paddingLeft: 0,
                    paddingRight: 0,
                }));

            // Add cursor
            
           var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
              maxDeviation: 0,
              categoryField: "x",
              renderer:  am5xy.AxisRendererX.new(root, {
                minGridDistance: 70
              }),
              // tooltip: am5.Tooltip.new(root, {})
            }));
            var label2 = am5.Label.new(root, {
                text: "[#666] Currency Pairs [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );


            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            var label1 = am5.Label.new(root, {
                rotation: -90,
                y: am5.p50,
                text: this.$parent.$parent.tradingprofitTab=='Pips' ? 'Profit (Pips)' : 'Profit ('+this.store.traderDetail.trader.stats.profile.baseCurrencyName+')',
                fill: am5.color(0x666666),
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );


             let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });

             var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            chart.get("colors").set("colors", [
                am5.color(0xff7200),
            ]);
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });

            // Create series
            
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                legendLabelText: this.$parent.$parent.tradingprofitTab=='Pips' ? 'Profit (Pips)' : 'Profit ('+this.store.traderDetail.trader.stats.profile.baseCurrencyName+')',
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y",
                sequencedInterpolation: true,
                categoryXField: "x",
                min : 0,
                tooltip: am5.Tooltip.new(root, {
                    labelText:"{categoryX} : {valueY}"
                })
            }));

            series.columns.template.setAll({  width: am5.percent(50),});
            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });


            // Set data
            var data = this.store.tradingPerformanceChart.series[0].data;
            xAxis.data.setAll(data);
            legend.data.push(series);
            series.data.setAll(data);
            // Make stuff animate on load
            series.appear(1000);
            chart.appear(1000, 100);
        },
        drawBestWorstPerformaceChart(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'tradingperformanceDashboardChart');
            d?.dispose();
            var root = am5.Root.new("tradingperformanceDashboardChart");
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root),
            ]);
            if(this.performanceFilter == 'Ranking'){
                root.numberFormatter.setAll({
                  numberFormat: "#a",
                  smallNumberThreshold: 0.001
                });
            }
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: false,
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                paddingLeft: 0,
                paddingRight: 0,
            }));

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
              behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);
            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
              maxDeviation: 0.2,
              baseInterval: {
                timeUnit: "day",
                count: 1
              },
              renderer: am5xy.AxisRendererX.new(root, {}),
            }));
            var label2 = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );
            let inversedJson = {}
            if(this.performanceFilter == 'Ranking'){
                inversedJson = {
                    renderer: am5xy.AxisRendererY.new(root, {
                        inversed: true
                    })
                }
            }else{
                inversedJson = {
                    renderer: am5xy.AxisRendererY.new(root, {})
                }
            }
            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, inversedJson));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            let json = {};
            if(this.performanceFilter == 'Ranking'){
                json = {
                    rotation: -90,
                    text: "[#666] Ranking [/]",
                    y: am5.p50,
                    centerX: am5.p50,
                    fontSize: 12,
                }
            }else{
                json = {
                    rotation: -90,
                    text: "[#666] Best and Worst Trade [/]",
                    y: am5.p50,
                    centerX: am5.p50,
                    fontSize: 12,
                }
            }
            var label1 = am5.Label.new(root, json)
            yAxis.children.unshift( label1 );

             chart.get("colors").set("colors", [
                am5.color(0xff7200),
                am5.color(0x4572a7),
            ]);

             let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });
            var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });
            this.store.tradingPerformanceChart.series.forEach((val,index) =>{
                if(val.data.length){
                    var series = chart.series.push(am5xy.LineSeries.new(root, {
                    legendLabelText: this.performancelabeljson[this.performanceFilter][index],
                      name: "Series",
                      xAxis: xAxis,
                      yAxis: yAxis,
                      valueYField: "y",
                      valueXField: "x",
                      tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueX.formatDate('dd MMM yyyy')} : {valueY}"
                      })
                    }));
                    series.strokes.template.setAll({
                        strokeWidth: 2,
                    });
                // Set data
                series.data.setAll(val.data);
                legend.data.push(series);
                series.appear(1000);
                }
            })
            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
            chart.appear(1000, 100);

        },
        getTradingPerformanceChartData(){
            let formData = {};
            let type = ''
            if(this.performanceFilter == 'Include Unrealized PnL' && this.$parent.$parent.tradingprofitTab == this.store.traderDetail.trader.stats.profile.baseCurrencyName){
                type = 'TOTAL_PROFIT_MONEY,OPEN_PROFIT_MONEY'
            }else if(this.performanceFilter == 'Include Unrealized PnL' && this.$parent.$parent.tradingprofitTab == 'Pips'){
                type = 'TOTAL_PROFIT,OPEN_PROFIT'
            }else if(this.performanceFilter == 'Closed Trades Only' && this.$parent.$parent.tradingprofitTab == this.store.traderDetail.trader.stats.profile.baseCurrencyName){
                type = 'CLOSED_PROFIT_MONEY'
            }else if(this.performanceFilter == 'Closed Trades Only' && this.$parent.$parent.tradingprofitTab == 'Pips'){
                type = 'CLOSED_PROFIT'
            }else if(this.performanceFilter == 'Trade Volume'){
                type = 'WIN_TRADES,LOSE_TRADES,BREAK_EVEN_TRADES'
            }else if(this.performanceFilter == 'By Pair' && this.$parent.$parent.tradingprofitTab == this.store.traderDetail.trader.stats.profile.baseCurrencyName){
                type = 'CURRENCY_CLOSED_PROFIT_MONEY'
            }else if(this.performanceFilter == 'By Pair' && this.$parent.$parent.tradingprofitTab == 'Pips'){
                type = 'CURRENCY_CLOSED_PROFIT'
            }else if(this.performanceFilter == 'Best-Worst'){
                type = 'BEST_TRADE,WORST_TRADE'
            }else if(this.performanceFilter == 'Ranking'){
                 type = 'RANKING'
            }
            this.store.callTradingPerformanceChartData(formData,true,this.store.userSelectedAccount.providerId,this.$parent.$parent.dateDropSelected.key,type).then(() =>{
                    if(Object.keys(this.store.tradingPerformanceChart).length && this.store.tradingPerformanceChart.series && this.store.tradingPerformanceChart.series.length > 0)
                    am5.ready(() => {
                        if(this.performanceFilter == 'By Pair'){
                            this.drawPairsPerformanceChart()
                        }else if(this.performanceFilter == 'Best-Worst' || this.performanceFilter == 'Ranking'){
                            this.drawBestWorstPerformaceChart()
                        }else{
                           this.drawPerformanceChart()
                        }
                    });
                })
        }
    },
    mounted() {
        this.getTradingPerformanceChartData()
    },
}
</script>
<style>
    .tradingperformanceChart{
        height: 400px;
        width: 100%;
    }
</style>