<template>
    <div id="tradingperformanceChart" class="tradingperformanceChart" v-show="Object.keys(store.userChartWithLeader).length && store.userChartWithLeader.series.length && store.userChartWithLeader.series[0].data && store.userChartWithLeader.series[0].data.length"></div>
    <div class="nodataError d-flex align-items-center justify-content-center flex-column" v-if="!store.singleLoading && Object.keys(store.userChartWithLeader).length && ( !store.userChartWithLeader.series.length || (store.userChartWithLeader.series.length && store.userChartWithLeader.series[0].data && !store.userChartWithLeader.series[0].data.length)) ">
       <img width="80" class="mb-2" src="/assets/images/nodataError.png" :alt="$t('no_data.nodataAlt')" />
        <p class="midgray">{{$t('no_data.noData')}}</p>
    </div>
    <!-- !(store.userChartWithLeader.series[0].data.length == 1 && store.userChartWithLeader.series[0].data[0].y==0) -->
</template>

<script>
import { myStore } from "@/store/pinia-store";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    props:{
        chartData: {
            default: []
        },
    },
    data() {
        return{ 
            chartDatas : 1
        }
    },
    methods: {
        drawPerformanceChart(){
           let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'tradingperformanceChart');
                d?.dispose();
                let root = am5.Root.new('tradingperformanceChart');

                root.numberFormatter.set("numberFormat", "#,###.00"); 
                root.dateFormatter.setAll({
                    dateFormat: "dd MMM yyyy",
                    dateFields: ["valueX"],
                });
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
                // Create chart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        paddingRight: 0,
                        paddingLeft : 0,
                        //paddingTop : 0,
                        // panX: false,
                        // panY: false,
                        // wheelX: "zoomY",
                        // wheelY: "zoomX",
                        // pinchZoomX: true
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        groupData: true,
                        //strictMinMax: true,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        startLocation: 1,
                        endLocation: 1
                        // tooltip: am5.Tooltip.new(root, {})
                    })
                );
                xAxis.get("dateFormats")["day"] = "dd MMM";
                xAxis.get("periodChangeDateFormats")["day"] = "YYYY";
                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        min: null,
                        // strictMinMax: true,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                // xAxis.get("dateFormats")["day"] = "MM/dd";
                // xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

                // let yRenderer = yAxis.get("renderer");
                // yRenderer.grid.template.setAll({
                //     stroke: am5.color(0xFF0000),
                //     strokeWidth: 2
                // });

                //let range = yAxis.createAxisRange(rangeDataItem);

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                // Add series 
                var series = []
                if(this.chartData[0].data.length == 1){
                    series = chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                            minBulletDistance: 10,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: "y",
                            valueXField: "x",
                            tooltip: am5.Tooltip.new(root, {
                                // pointerOrientation: "horizontal",
                               labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]Profit[/] : {valueY}"
                            }),
                        })
                    );
                    series.columns.template.setAll({
                        visible: true,
                    });
                    series.columns.template.setAll({
                        width: am5.percent(5)
                    });
                    series.columns.template.set(
                        "fillGradient",
                        am5.LinearGradient.new(root, {
                            stops: [{opacity: 0.2,},{opacity: 0.1,},],
                        })
                    );
                } else {
                    series = chart.series.push(
                        am5xy.SmoothedXLineSeries.new(root, {
                            minBulletDistance: 10,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: "y",
                            valueXField: "x",
                            tooltip: am5.Tooltip.new(root, {
                                // pointerOrientation: "horizontal",
                               labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]Profit[/] : {valueY}"
                            }),
                        })
                    );
                    series.fills.template.setAll({
                        visible: true,
                    });

                    series.fills.template.set(
                        "fillGradient",
                        am5.LinearGradient.new(root, {
                            stops: [{opacity: 0.2,},{opacity: 0.1,},],
                        })
                    );
                    series.strokes.template.setAll({
                        strokeWidth: 2,
                    });
                }

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["x"],
                });
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // chart.zoomOutButton.set("forceHidden", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });

                // Set data
                series.data.setAll(this.chartData[0].data);

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(500, 100);
        }
    },
    mounted() {
        if(this.chartData.length && this.chartData[0].data && this.chartData[0].data.length){
            am5.ready(() => {
                this.drawPerformanceChart()
            })
        }
    },
}
</script>
<style>
    .tradingperformanceChart{
        height: 300px;
        width: 100%;
    }
    .tradingperformanceChart>div {
        display: none;
    }

    .tradingperformanceChart>div:last-child {
        display: block !important;
    }
</style>